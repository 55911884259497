import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, TrailguidePlugin } from "../../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Just a short drive from the coast, all the trouble, show and shine of the
Côte d'Azur is gone. Surrounded by the Alpes-Maritimes, the medieval village
of Sospel lies quietly at the Bévéra river. Although spring is here, and the
trails are in a great shape, not many tourists found their way to Sospel. We
only see a few bikers on the main square, no one on the trails. Zoom and move
the map below, click on the trails to get further information or download the
gpx tracks. You can also open the region directly in the
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=13,43.8933,7.4851&map=cyclemap"
      }}>{`web-app`}</a>{`
and use it for navigation.`}</p>
    <TrailguidePlugin content="lat=43.8933&lng=7.4851&coverage=6" mdxType="TrailguidePlugin" />
    <p className="text-xs mb-16" align="center">
      <p>{`Open these trails directly in the `}<a parentName="p" {...{
          "href": "https://trailguide.net/?pos=13,43.8933,7.4851"
        }}>{`web-app`}</a></p>
    </p>
    <h4>{`The ridge`}</h4>
    <p>{`At the start of a trail called THE RIDGE, you have an awesome view to the
Mediterranean Sea, but also into the higher mountains of the Alpes Maritimes.
In spring they are still covered in snow. Later in the season, they are ready
for some alpine adventures.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2750"
      }}>{`trailguide.net/2750`}</a></p>
    <Image src="destinations/sospel_8.jpg" mdxType="Image" />
    <br />
    <h4>{`Holy Trail`}</h4>
    <p>{`But lets start with the trails directly around Sospel. They are a bit more
on the wild and loose side.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2755"
      }}>{`trailguide.net/2755`}</a></p>
    <Image src="destinations/sospel_7.jpg" mdxType="Image" />
    <br />
    <p>{`The Holy Trail is one of the most technical trails. Steep, switchbacks and
loose rocks. Tanja Naber and Franzi Meyer are off the brakes on one of the
fast parts inbetween.`}</p>
    <Image src="destinations/sospel_6.jpg" mdxType="Image" />
    <br />
    <h4>{`Calle Castagne`}</h4>
    <p>{`The further you come down, the thicker the forest becomes. Narrow valleys,
little creeks, tricky sections.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2753"
      }}>{`trailguide.net/2753`}</a></p>
    <Image src="destinations/sospel_5.jpg" mdxType="Image" />
    <br />
    <h4>{`Cime du Bosc`}</h4>
    <p>{`But now let's go to our favourite side of Sospel. The trails from the Cime
du Bosc. The shuttle brings you all the way to the start of the trails.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2819"
      }}>{`trailguide.net2819`}</a></p>
    <Image src="destinations/sospel_2.jpg" mdxType="Image" />
    <br />
    <h4>{`The Ridge`}</h4>
    <p>{`From the Cime du Bosc, trails start in all directions. Back to Sospel, to
Breil-Sur-Roya, or south, all the way down to the Italian side.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2750"
      }}>{`trailguide.net/2750`}</a></p>
    <Image src="destinations/sospel_9.jpg" mdxType="Image" />
    <br />
    <h4>{`The Ridge`}</h4>
    <p>{`The trail starts steep, with some rocky offcamber sections, followed by an
aweseome part with lots of corners. The ridge goes straight towards the little
mountain village of Piene Haute, that you can see in the background. But then
makes a sharp left turn back towards Breil-Sur-Roya.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2750"
      }}>{`trailguide.net/2750`}</a></p>
    <Image src="destinations/sospel_10.jpg" mdxType="Image" />
    <br />
    <h4>{`Cougle East`}</h4>
    <p>{`Breil-Sur-Roya is the end of several trails. The beautiful little village
lies in a narrow valley at the Roya river. Franzi Meyer takes a switchback
on a short part of an old roman road.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2751"
      }}>{`trailguide.net/2751`}</a></p>
    <Image src="destinations/sospel_1.jpg" mdxType="Image" />
    <br />
    <h4>{`Breil-Sur-Roya`}</h4>
    <p>{`Relaxed life in the village. Breil-Sur-Roya is located at the road from
Ventimiglia to Cuneo via the Col de Tende."`}</p>
    <Image src="destinations/sospel_3.jpg" mdxType="Image" />
    <br />
    <h4>{`Panoramic`}</h4>
    <p>{`From the Cime du Bosc you can also take a long trail to the Italian side.
The terrain changes all the time and descent is very versatile. Over 800hm
and 7km later, you get the possibility for a nice Italian brioche and coffee.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2752"
      }}>{`trailguide.net/2752`}</a></p>
    <Image src="destinations/sospel_4.jpg" mdxType="Image" />
    <br />
    <h4>{`Panoramic`}</h4>
    <p>{`The trail becomes more technical towards the end. Looking back, you see the
village of Piene Haute, sitting on a narrow ridge.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2752"
      }}>{`trailguide.net/2752`}</a></p>
    <Image src="destinations/sospel_12.jpg" mdxType="Image" />
    <br />
    <h4>{`Col de l'Arma`}</h4>
    <p>{`From the Panoramic, you can turn left and go through Piene Haute. The houses
crouch onto the narrow ridge. Here a trail with lots of switchbacks starts.
It winds down the steep terrain below the village.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2820"
      }}>{`trailguide.net/2820`}</a></p>
    <Image src="destinations/sospel_11.jpg" mdxType="Image" />
    <br />
    <h4>{`Basséra Valley`}</h4>
    <p>{`To go back to Sospel, you can take a challenging trail through the Basséra
Valley. It has not one really difficult section, but is constantly demanding
the bikers concentration and work. That gets rewarded with an awesome
flow-tech experience.
Trail: `}<a parentName="p" {...{
        "href": "https://trailguide.net/2821"
      }}>{`trailguide.net/2821`}</a></p>
    <Image src="destinations/sospel_13.jpg" mdxType="Image" />
    <br />
    <h4>{`Sospel`}</h4>
    <p>{`Back to Sospel, there is nothing left than to enjoy the after ride,
sitting outside in one of the bars at the Bévéra River. You find all
trails on our free web-app
`}<a parentName="p" {...{
        "href": "https://trailguide.net/?pos=12,43.8984,7.4734&map=cyclemap"
      }}>{`trailguide.net`}</a>{`.
Open Trailguide on the smartphone and use it for navigation. Or download the
GPX tracks for offline use or your gps device.`}</p>
    <Image src="destinations/sospel_14.jpg" mdxType="Image" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      